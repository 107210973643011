import React from 'react';
import { HeaderConfig, appliedConfig } from '../config/commonConfig';

function Features() {
  return (
    <section
      id="features"
      className={
        'section ' +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? 'px-lg-4 '
          : ' ')
      }
    >
      <div className="container">
        <h2 className="text-9 fw-600 text-center">App Features</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-5">
          {/* You can present your app features. It is all very easy! */}
        </p>
        <div className="row">
          <div className="col-lg-4 align-self-center">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-pencil-ruler" />
                  </div>
                  <h3>Recipe generation</h3>
                  <p>
                    Unleash the power of artificial intelligence to create
                    unique vegan recipes based on your input.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-cloud-upload-alt" />
                  </div>
                  <h3>Save and Organize</h3>
                  <p>
                    Love a particular recipe? Save it for future use!
                    RecipeWrangle allows you to create your personalised recipe
                    collection.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-tags" />
                  </div>
                  <h3>Free</h3>
                  <p>
                    RecipeWrangle is completely free to download and use, more
                    advanced features coming soon!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center mb-4 mb-xl-0">
            <img
              alt=""
              src="images/app/screenshot4-portrait.png"
              className="img-fluid"
            />
          </div>
          <div className="col-lg-4 align-self-center">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-lock" />
                  </div>
                  <h3>Fully Secured</h3>
                  <p>
                    Indulge in culinary delights worry-free – our recipe app
                    prioritizes both flavor and the security of your data.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-paper-plane" />
                  </div>
                  <h3>Share the Joy of Cooking</h3>
                  <p>
                    Spread the love for plant-based living by sharing your
                    favourite recipes with friends and family.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-sync-alt" />
                  </div>
                  <h3>Sustainability</h3>
                  <p>
                    RecipeWrangle encourages mindful cooking by suggesting
                    recipes that utilise ingredients you already have.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
